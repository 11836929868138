* 
  padding: 0
  margin-left: 0
  margin-right: 0
  border: 0
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box

  &:before, &:after
    -moz-box-sizing: border-box
    -webkit-box-sizing: border-box
    box-sizing: border-box

\:focus, :active
  outline: none

a
  &:focus, &:active
    outline: none

nav, footer, header, aside
  display: block

html, body
  margin: 0
  height: 100%
  width: 100%
  font-size: 100%
  line-height: 1
  font-size: 14px
  -ms-text-size-adjust: 100%
  -moz-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%

input, button, textarea
  font-family: inherit

input::-ms-clear
  display: none

button
  cursor: pointer

  &::-moz-focus-inner
    padding: 0
    border: 0

a
  text-decoration: none

  &:visited, &:hover
    text-decoration: none

ul li
  list-style: none

img
  vertical-align: top

h1, h2, h3, h4, h5, h6
  font-size: inherit
  font-weight: 400