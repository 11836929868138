body {
  min-width: 320px;
}

.App {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.container {
  width: 80%;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

@import 'fonts.sass';
@import 'reset.sass';
@import 'header.scss';
@import 'about.scss';
@import 'sliders.scss';
@import 'page.scss';
@import 'team.scss';
@import 'feedback.scss';
@import 'spinner.scss';
@import 'footer.scss';
@import 'blog.scss';
@import 'modal.scss';
@import 'singlepost.scss';

.menu__button {
  position: absolute;
  z-index: 1;
  width: 376px;
  height: 67px;
  background: #201335;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 17px;
  transition: 0.3s ease-out;
  @media (max-width: 375px) {
    width: 300px;
  }
  &:hover {
    left: -20px;
    cursor: pointer;
  }
}

.menu__button p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  position: relative;
  @media (max-width: 530px) {
    font-size: 16px;
  }
}
