.header__wrapper {
  position: relative;
  width: 100%;
  height: 690px;
  overflow: hidden;
}

.header__wrapper .slick-list {
  width: 100% !important;
  margin: 0 auto;
}

.header__wrapper .slick-dots {
  position: absolute;
  bottom: 30px;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '';
  text-align: center;
  opacity: 0.25;
  background-color: #000;
  margin: 0 10px;
  border-radius: 50%;
}

.header__first-slide {
  width: 100%;
  height: 690px;
  background: linear-gradient(90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    linear-gradient(-90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    url(../img/hassp.png) center/cover;
}

.header__second-slide {
  width: 100%;
  height: 690px;
  background: linear-gradient(90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    linear-gradient(-90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    url(../img/protect.png) center/cover;
}

.header__third-slide {
  width: 100%;
  height: 690px;
  background: linear-gradient(90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    linear-gradient(-90deg, #5b5757 5%, rgba(255, 255, 255, 0) 70%),
    url(../img/mchs.jpg) center/cover;
}

.header__first-slide p,
.header__second-slide p,
.header__third-slide p {
  margin: 5px 0;
  font-size: 17px;
}

.header__first-slide span,
.header__second-slide span,
.header__third-slide span {
  font-weight: bold;
}

.header__slide-title {
  width: 480px;
  height: 45px;
  margin-top: 220px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  @media (max-width: 530px) {
    font-size: 32px;
  }
}

.header__slide-content {
  width: 455px;
  margin-top: 20px;
  padding: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 15px;
  background-color: rgba(126, 126, 126, 0.8);
  @media (max-width: 985px) {
    font-size: 17px;
  }
  @media (max-width: 530px) {
    font-size: 17px;
    width: 360px;
    line-height: 18px;
  }
  @media (max-width: 430px) {
    font-size: 17px;
    width: 260px;
    line-height: 18px;
  }
  &:hover {
    cursor: pointer;
  }
}

.header__navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(126, 126, 126, 0.8);
  padding: 25px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.header__menu {
  width: 570px;
  transition: all 0.3s ease-out;
  @media (max-width: 1200px) {
    width: 460px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 280px;
    position: absolute;
    right: 0;
    z-index: 2;
    padding-top: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.header__menu a {
  color: #ffffff !important;
  text-decoration: none;
}

.menu__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 50px;
  }
}

.menu__item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 17px;
  color: #ffffff;
  position: relative;
  text-decoration: none;
  @media (max-width: 1200px) {
    font-size: 11px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 8px;
    border-radius: 5px;
    font-size: 16px;
    background: rgba(126, 126, 126, 0.8);
    padding: 5px;
  }
}

.menu__item:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px; /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0; /*задаём длинну линии до наведения курсора*/
  height: 1px; /*задаём ширину линии*/
  background-color: #ffffff; /*задаём цвет линии*/
  content: '';
  transition: width 0.3s ease-out; /*задаём время анимации*/
  @media (max-width: 1025px) {
    display: none;
  }
}

.menu__item:hover {
  cursor: pointer;
}

.menu__item:hover:after,
.menu__item:focus:after {
  width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}

.header__burger {
  width: 30px;
  height: 30px;
  top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 5555;
  & div {
    width: 42px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 10px;
    transition: all 0.3s ease-out;
    transform-origin: 1px;
  }
}

.header__logo,
.footer__logo {
  width: 172px;
  height: 30px;
  background: url(../img/logo.png) center/cover;
}

.header__contacts {
  @media (max-width: 768px) {
    display: none;
  }
}

.header__phone {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #ffffff;
}

.header__email {
  margin-top: 3px;
  text-align: right;
  color: #ffffff;
}

.header__email a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff !important;
  position: relative;
  text-decoration: none;
}

.header__email a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px; /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0; /*задаём длинну линии до наведения курсора*/
  height: 1px; /*задаём ширину линии*/
  background-color: #ffffff; /*задаём цвет линии*/
  content: '';
  transition: width 0.3s ease-out; /*задаём время анимации*/
}

a:hover:after,
a:focus:after {
  width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}

.social__links {
  position: absolute;
  bottom: 36px;
  width: 226px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  @media (max-width: 530px) {
    width: 100%;
    bottom: 15px;
  }
  @media (max-width: 430px) {
    display: none;
  }
}

.links__item {
  width: 30px;
  height: 22px;
  transform: scale(1.1);
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
}

.links__item-y {
  background: url(../img/y.png) center/cover;
}
// .links__item-w {
//   background: url(../img/w.png) center/cover;
// }
.links__item-t {
  background: url(../img/t.png) center/cover;
}
.links__item-v {
  background: url(../img/v.png) center/cover;
}
.links__item-i {
  background: url(../img/i.png) center/cover;
}
.links__item-f {
  background: url(../img/f.png) center/cover;
  height: 30px;
}
.links__item-ok {
  background: url(../img/ok.png) center/cover;
  height: 29px;
  width: 21px;
}

.header__feedback {
  position: absolute;
  z-index: 1;
  right: -70px;
  bottom: 36px;
  width: 376px;
  height: 67px;
  background: #fce762;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 17px;
  transition: 0.3s ease-out;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #201335;
  &:hover {
    right: -10px;
    cursor: pointer;
  }
  @media (max-width: 654px) {
    width: 290px;
    height: 50px;
    bottom: 90px;
    font-size: 16px;
  }
  @media (max-width: 430px) {
    bottom: 20px;
    height: 67px;
  }
}

.drop__menu {
  position: absolute;
  width: 480px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #201335;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1200px) {
    width: 350px;
  }
  @media (max-width: 1025px) {
    width: 100%;
    margin-top: -10px;
  }
}

.drop__item {
  width: 100%;
  padding: 10px 5px 10px;
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  color: #201335;
  border-bottom: 1px solid #201335;
  transition: all 0.3s ease-in-out;
  @media (max-width: 635px) {
    font-size: 12px;
    line-height: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    color: rgb(161, 161, 161);
    cursor: pointer;
  }
}
