.team__wrapper {
  position: relative;
}

.team__row {
  width: 100%;
  overflow: hidden;
}

.team__row .swiper-button-next,
.team__row .swiper-button-prev {
  color: #FCE762;
  top: 105px;
  display: none !important;
  @media (max-width: 1600px) {
    display: block !important;
  }
  @media (max-width: 1150px) {
    top: 95px;
  }
}

.team__row .swiper-button-next {
  right: -5px;
}
.team__row .swiper-button-prev {
  left: 5px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 35px !important;
}

.team__first {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.team__second {
  width: 100%;
  max-width: 1280px;
  margin: 55px auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1170px) {
    width: 70%;
  }
  @media (max-width: 950px) {
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
}

.team__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.team__photo {
  width: 230px;
  height: 210px;
  border: 5px solid #FCE762;
  border-radius: 50%;
  margin-bottom: 10px;
  @media (max-width: 1150px) {
    width: 195px;
    height: 182px;
  }
  // @media (max-width: 800px) {
  //   width: 256px;
  //   height: 240px;
  // }
}

.team__photo-one {
  background: url(../img/team_1.png) -20px -40px / 250px no-repeat;
  @media (max-width: 1150px) {
    background-position: -35px -40px;
  }
}
.team__photo-two {
  background: url(../img/team_2.jpeg) 0 0 / cover no-repeat;
}
.team__photo-three {
  background: url(../img/team_3.jpeg) 0 0 / cover no-repeat;
}
.team__photo-four {
  background: url(../img/team_4.jpeg) 0 0 / cover no-repeat;
}
.team__photo-five {
  background: url(../img/team_5.jpg) -45px 0 / cover no-repeat;
}

.team__name {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #201335;
  margin-bottom: 10px;
  @media (max-width: 950px) {
    font-size: 18px;
  }
}

.team__discr {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #201335;
  @media (max-width: 950px) {
    font-size: 12px;
  }
}

.team__goals-title {
  margin: 50px auto 50px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 56px;
  color: #e6e6e6;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 50px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
    margin: 20px 0;
    padding-bottom: 0;
  }
}

.team__goals {
  margin: 0 auto 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  @media (max-width: 1480px) {
    margin-bottom: 0;
  }
  @media (max-width: 1150px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 678px) {
    grid-template-columns: 1fr 1fr;
    margin: 0;
  }
}

.goal__item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 170px;
  margin: 0 auto;
  max-width: 140px;
}

.goal__item-one {
  @media (max-width: 1150px) {
    grid-column: 1/2;
  }
  @media (max-width: 678px) {
    grid-column: 1;
  }
}
.goal__item-two {
  @media (max-width: 1150px) {
    grid-column: 3/4;
  }
  @media (max-width: 678px) {
    grid-column: 2;
  }
}
.goal__item-three {
  @media (max-width: 1150px) {
    grid-column: 5/6;
  }
  @media (max-width: 678px) {
    grid-column: 1;
  }
}
.goal__item-four {
  @media (max-width: 1150px) {
    grid-column: 2/3;
  }
  @media (max-width: 678px) {
    grid-column: 2;
  }
}
.goal__item-five {
  @media (max-width: 1150px) {
    grid-column: 4/5;
  }
  @media (max-width: 678px) {
    grid-column: 1/3;
  }
}

.goal__number {
  width: 90px;
  height: 90px;
  background: #FCE762;
  border-radius: 50%;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: #201335;
  padding: 16px 0;
  margin-bottom: 5px;
}

.goal__title {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #201335;
  margin-bottom: 5px;
  @media (max-width: 870px) {
    font-size: 18px;
  }
}
.goal__content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #201335;
  @media (max-width: 400px) {
    font-size: 13px;
  }
}

.team__wrapper .menu__button {
  left: -70px;
  bottom: 500px;
  @media (max-width: 1480px) {
    bottom: 30px;
  }
  @media (max-width: 400px) {
    left: -120px;
  }
}
