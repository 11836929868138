.block__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: #201335;
  margin: 50px 20px;
  text-align: center;
  @media (max-width: 450px) {
    font-size: 22px;
    margin: 20px 20px;
    line-height: 28px;
  }
}

.line {
  width: 20vw;
  height: 1px;
  background-color: #201335;
  @media (max-width: 425px) {
    background-color: #ffffff;
  }
}

.blog__row {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 1280px;
}

.blog__item {
  margin-bottom: 70px;
  height: 450px;
}

.blog__img {
  width: 100%;
}

.blog__title {
  margin: 20px auto 20px;
  max-width: 97%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  color: #201335;
  @media (max-width: 860px) {
    font-size: 23px;
  }
}

.blog__category {
  margin: 5px auto 5px;
  max-width: 97%;
  font-family: Montserrat;
  font-style: 400;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #bbbaba;
  text-transform: uppercase;
}

.blog__content {
  margin: 5px auto 0;
  max-width: 97%;
  height: 73px;
  overflow: hidden;
  position: relative;
  font-family: Montserrat;
  font-style: 400;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #201335;
}

.blog__content:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 10em;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255) 100%
  );
  pointer-events: none;
}

.blog__arrow {
  margin: 15px auto 0;
  max-width: 97%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}
.blog__arrow a {
  position: relative;
  color: #201335 !important;
  text-decoration: none;
}

.blog__arrow a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px; /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0; /*задаём длинну линии до наведения курсора*/
  height: 1px; /*задаём ширину линии*/
  background-color: #201335; /*задаём цвет линии*/
  content: "";
  transition: width 0.3s ease-out; /*задаём время анимации*/
}

a:hover:after,
a:focus:after {
  width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}

// ============================= Slider

.blog__plug {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.blog__plug .blog__content {
  margin: 5px auto 0;
  max-width: 97%;
  height: 100px;
  overflow: hidden;
  position: relative;
  font-family: Montserrat;
  font-style: 400;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #201335;
}

.blog__plug .blog__content:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 10em;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 100%,
    rgb(255, 255, 255) 100%
  );
  pointer-events: none;
}

.blog .swiper-button-next,
.blog .swiper-button-prev {
  top: 110px;
  color: #201335 !important;
  @media (max-width: 500px) {
    display: none !important;
  }
  
}
