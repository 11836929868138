@keyframes ldio-rfwki38ms6 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-rfwki38ms6 div {
  box-sizing: border-box !important;
}
.ldio-rfwki38ms6 > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #201335;
  border-color: #FCE762 transparent #FCE762 transparent;
  animation: ldio-rfwki38ms6 2s linear infinite;
}

.ldio-rfwki38ms6 > div:nth-child(2),
.ldio-rfwki38ms6 > div:nth-child(4) {
  width: 108px;
  height: 108px;
  top: 46px;
  left: 46px;
  animation: ldio-rfwki38ms6 2s linear infinite reverse;
}
.ldio-rfwki38ms6 > div:nth-child(2) {
  border-color: transparent #201335 transparent #201335;
}
.ldio-rfwki38ms6 > div:nth-child(3) {
  border-color: transparent;
}
.ldio-rfwki38ms6 > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-rfwki38ms6 > div:nth-child(3) div:before,
.ldio-rfwki38ms6 > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #FCE762;
  border-radius: 50%;
}
.ldio-rfwki38ms6 > div:nth-child(3) div:after {
  left: -16px;
  top: 48px;
}

.ldio-rfwki38ms6 > div:nth-child(4) {
  border-color: transparent;
}
.ldio-rfwki38ms6 > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-rfwki38ms6 > div:nth-child(4) div:before,
.ldio-rfwki38ms6 > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 30px;
  background: #201335;
  border-radius: 50%;
}
.ldio-rfwki38ms6 > div:nth-child(4) div:after {
  left: -16px;
  top: 30px;
}
.loadingio-spinner-double-ring-238aqtlwgn3 {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-rfwki38ms6 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-rfwki38ms6 div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
