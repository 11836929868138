.spinner__block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.single__post {
  width: 100%;
}

.singleblog__title h2 {
  position: relative;
  margin: 50px 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 35px;
  color: #e6e6e6;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 50px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
    margin: 20px 0;
    padding-bottom: 0;
  }
}

// .img__container {
//   margin: 0 auto;
//   width: 40%;
// }

// .singleblog__img {
//   width: 100%;
//   border-radius: 50%;
// }

.single__post p {
  margin-bottom: 10px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #201335;
  margin-bottom: 20px;
}

.single__post h3 {
  margin: 20px auto 20px;
  max-width: 97%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  color: #201335;
  @media (max-width: 860px) {
    font-size: 23px;
  }
}

.blog__body img {
    width: 100%;
    border-radius: 10px;
}

.blog__author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.author__img {
  border-radius: 50%;
}

.author__name {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #201335;
  margin-left: 20px;
  @media (max-width: 950px) {
    font-size: 18px;
  }
}
