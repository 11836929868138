.modal {
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &__content {
    border-radius: 20px;
    padding: 30px;
    background-color: #ffffff;
    transform: scale(0.5);
    transition: 0.4s all;
    &.active {
      transform: scale(1);
    }
  }
  &__form {
    width: 240px;
  }
  &__input {
    height: 40px;
    border: 1px solid #FCE762;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 20px;
    font-family: Montserrat;
    font-style: 400;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #201335;
    & input {
      width: 100%;
    }
  }
  &__button {
    margin: 10px auto;
    width: 100%;
    height: 40px;
    background: #FCE762;
    transition: 0.3s ease-out;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #201335;
    &:hover {
        transform: translateY(-5px);
    }
  }

  &__message {
    height: 120px;
    align-items: start;
    padding-top: 10px;
  }
}
